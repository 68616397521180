<template>
  <div class="page page--groups" :key="$store.state.updateTimestamp">
    <v-row>
      <v-col cols="12" sm="9" md="5">
        <h1 class="page__title">
          {{ $t(isGroupAdmin ? 'nav_billing' : 'nav_groups') }}
        </h1>
        <p class="page__subtitle">
          {{ $t('group_text') }}
        </p>
      </v-col>

      <v-col
        v-if="isCompanyAdmin"
        cols="12"
        sm="3"
        md="4"
        offset-md="3"
        align="right"
        align-self="end"
      >
        <v-btn
          class="pkmn-button pkmn-button--full-width-mobile"
          color="primary"
          large
          @click="openAddForm"
        >
          {{ $t('home_add_group') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <groups-data-table
          :data="groups"
          @edit="openEditForm"
          @delete="openDeleteConfirmDialog"
        />
      </v-col>
    </v-row>

    <group-form
      ref="form"
      v-if="isAddEditFormOpening"
      :data="selectedRecord"
      @submitAdd="addGroup"
      @submitEdit="editGroup"
      @close="isAddEditFormOpening = false"
    />

    <confirm-dialog
      ref="confirmDeleteDialog"
      warning
      :title="confirmDeleteTitle"
      :text="confirmDeleteText"
      @confirm="deleteGroup"
      @close="selectedRecord = null"
    />
  </div>
</template>

<script>
import api from '@/helpers/api'
import auth from '@/auth'
import companyHelpers from '@/helpers/company'
import helpers from '@/helpers'
import userHelpers from '@/helpers/user'

import ConfirmDialog from '@/components/ConfirmDialog'
import GroupsDataTable from '@/components/Groups/DataTable'
import GroupForm from '@/components/Groups/FormGroup'

export default {
  name: 'GroupsView',
  components: { ConfirmDialog, GroupsDataTable, GroupForm },
  data() {
    return {
      auth: auth,
      userHelpers: userHelpers,
      confirmDeleteTitle: '',
      confirmDeleteText: this.$t('remove_group_text'),
      selectedRecord: null,
      isAddEditFormOpening: false,
      isCompanyAdmin: false,
    }
  },
  computed: {
    groups() {
      const profiles = this.$store.state.companyProfiles[this.$store.state.companyData?.id] || [];
      const allowedGroupIds = profiles.map(profile => profile.parent.group_id).filter(item => !!item)
      const groupData = this.$store.state.groupData || [];
      if (this.isCompanyAdmin) {
        return groupData
      }

      return groupData.filter(item => allowedGroupIds.includes(item.id))
    },
    isGroupAdmin() {
      return auth.isGroupAdmin()
    }
  },
  methods: {
    async checkUserRoleInCompany(role) {
      return userHelpers.checkUserRoleInCompany(
        helpers.getTokenInfo().uid,
        this.$store.state.companyData.id,
        role
      )
    },
    openAddForm() {
      this.selectedRecord = {}
      this.isAddEditFormOpening = true
    },
    async openEditForm(record) {
      this.selectedRecord = record

      // first, fetch all payment methods available in the system associated with the selected group
      const apiSettings = {
        method: 'get',
        service: 'billing',
        url: 'payment_methods%2F',
        params: {
          account_id: record.account_id
        }
      }

      try {
        const groupPaymentMethodsResponse = await api.promise(apiSettings)
        this.selectedRecord.payment_methods =
          groupPaymentMethodsResponse.payment_methods
        this.isAddEditFormOpening = true
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
      }
    },
    openDeleteConfirmDialog(record) {
      this.selectedRecord = record
      // prepare confirm dialog content
      this.confirmDeleteTitle = this.$t('remove_dialog_title', {
        object: record.name
      })
      this.$refs.confirmDeleteDialog.isOpening = true
    },
    addGroup(data) {
      this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('added_confirm_message', {
          object: `${this.$t('single_group')} "${data.name}"`
        })
      })
      this.isAddEditFormOpening = false
      this.loadData()
    },
    editGroup(data) {
      this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('updated_confirm_message', {
          object: `${this.$t('single_group')} "${data.name}"`
        })
      })
      this.isAddEditFormOpening = false
      this.loadData()
    },
    async deleteGroup() {
      const groupName = this.selectedRecord.name
      const apiSettings = {
        method: 'delete',
        service: 'users',
        url: `v1/groups/${this.selectedRecord.id}`
      }

      try {
        await api.promise(apiSettings)
        // re-fetch the group list again to reflect the change in data table
        await this.loadData()

        this.$store.dispatch('setSystemMessage', {
          type: 'success',
          message: this.$t('removed_confirm_message', {
            object: `${this.$t('single_group')} "${groupName}"`
          })
        })
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
      }
    },
    /**
     * Load the group list and their associated payment methods
     */
    async loadData() {
      await helpers.fetchDashboardData()
      // fetch payment options and "translate" them to consistent frontend variables
      await companyHelpers.fetchPaymentOptions(
        this.$store.state.companyData.country.code
      )

      // fetch current payment method for each group, and associate them with the group data
      const currentGroupPaymentMethods =
        await companyHelpers.fetchCurrentGroupPaymentMethod(
          this.$store.state.groupData
        )
      companyHelpers.setGroupActivePaymentMethod(
        this.$store.state.groupData,
        currentGroupPaymentMethods
      )

      this.$store.state.updateTimestamp = Date.now()

      this.isCompanyAdmin = await this.checkUserRoleInCompany('company_admin')
    }
  },
  async mounted() {
    await this.loadData()
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
</style>
